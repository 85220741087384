.otp-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.otp-input {
  flex: 1;
  max-width: 75px;
  height: 68px;
  border-radius: 5px;
  border: 1px solid #6432ff;
  font-size: 40px;
  font-weight: 800;
  margin: 0 4px;
}
.otp-container {
  margin-bottom: 30px;
}
.resent-button {
  color: #6432ff;
  margin: 0;
  padding: 0;
  border: none;
}
