.mobile-view {
  margin: 0;
  padding: 0;
  background-color: white;
  position: relative;
  overflow: hidden;
}

@media (max-width: 650px) {
  .mobile-view {
    width: 100%;
  }
}

@media (min-width: 651px) {
  .mobile-view {
    width: 350px;
    margin: 0 auto;
  }
}
