.active-wrapper {
  background-color: #8a2fbc;
  border-radius: 8px;
  font-size: 40px;
  padding: 11px 15px;
}
.subscription-text {
  margin-bottom: 30px;
}
.contact-text {
  margin-top: 30px;
  margin-bottom: 30px;
}
