:root {
  --deep-blue: #7d1fe8;
  --vivid-pink: #cd28b0;
  --light-lavender: #d2c6db;
  --pure-white: #ffffff;
  --soft-pink: #ffb2c5;
  --bright-orange: #f4541a;
  --vivid-purple: #c621c7;
  --electric-blue: #4f24ea;
  --fiery-orange: #f35514;
  --vivid-raspberry: #fa496b;
  --golden-yellow: #fec91d;
  --vivid-orange: #ff7540;
}
body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.open-sans-800 {
  font-family: "Open Sans", serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}
.grad-blue-pink {
  background: linear-gradient(
    90deg,
    var(--deep-blue) 0%,
    var(--vivid-pink) 100%
  );
}
.grad-crimson-magenta-purple {
  background: linear-gradient(214deg, #da3f52 0%, #db2de9 50%, #ad1ae7 100%);
}
.grad-blue-purple-orange {
  background: linear-gradient(
    79deg,
    var(--deep-blue) 0%,
    var(--vivid-purple) 50%,
    var(--fiery-orange) 100%
  );
}
.grad-lavender-white-pink-v {
  background: linear-gradient(
    180deg,
    var(--light-lavender) 0%,
    var(--pure-white) 50%,
    var(--soft-pink) 100%
  );
}
.grad-text-blue-pink {
  background: linear-gradient(
    90deg,
    var(--deep-blue) 0%,
    var(--vivid-pink) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.grad-text-yellow-orange {
  background: linear-gradient(
    90deg,
    var(--vivid-orange) 0%,
    var(--golden-yellow) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.red-background {
  background-color: #EE1E52 !important;
}
.fs-6 {
  font-size: 6px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-20 {
  font-size: 20px;
}
.fs-24 {
  font-size: 24px;
}
.fs-26 {
  font-size: 26px;
}
.fs-30 {
  font-size: 30px;
}
.lh-20 {
  line-height: 20px;
}
.lh-25 {
  line-height: 25px;
}
.lh-26 {
  line-height: 26px;
}
.lh-28 {
  line-height: 28px;
}
.lh-18 {
  line-height: 18px;
}
.fade-in {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.fade-out {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.rotate-180 {
  transform: rotate(180deg);
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  overflow: hidden;
}
@supports (-webkit-touch-callout: none) {
  .scrollbar-hidden {
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
  }
}
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #8a2fbc;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.footer-wrapper {
  margin-top: 30px;
}
.footer-line {
  height: 1px;
  background-color: #00000045;
}
.common-padding-x {
  padding-left: 28px;
  padding-right: 28px;
}
.common-padding-y {
  padding-top: 28px;
  padding-bottom: 28px;
}
.skeleton-box {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #e0e0e0;
  border-radius: 4px;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 40%,
      rgba(255, 255, 255, 0.281) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
.skeleton-text {
  height: 17px;
  width: 20px;
}
.skeleton-followers-text {
  height: 20px;
  width: 50px;
}
