.profile-wrapper {
  padding: 15px 20px;
  border-radius: 5px;
}
.profile-img-wrapper {
  height: 79px;
  width: 79px;
}
.profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}
.high-text {
  color: #66ae08;
}
.low-text {
  color: #ee1e52;
}
.graph-wrapper {
  background-color: #f3f3f3;
  border-radius: 8px;
  padding: 13px;
  padding-bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.skeleton-graph-wrapper {
  border-radius: 8px;
  padding: 13px;
  padding-bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 193px;
  width: 100%;
}
.activity-line {
  background-color: #f3f3f3;
  height: 1px;
}
.high-low-wrapper {
  gap: 10px;
}
.high-low-wrapper > p > span:nth-child(1) {
  margin-right: 3px;
}
.error-wrapper {
  height: 100px;
  width: 100%;
}
.warning-heading{
  margin-bottom: 0 !important; 
}
.warning-text{
  padding-left: 5px !important;
  padding-right: 5px !important;
  line-height: 19px;
}
.username-input{
  max-width: 95%;
  width: 100%;
  background-color: #ee1e52;
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  min-height: 40px !important;
  font-size: 14px !important;
  color: #FFFFFF;
  padding-left: 10px;
}
.username-error{
  text-align: start;
    width: 100%;
    max-width: 95%;
    margin-bottom: 0;
    margin-top: 1px;
}

.username-input::placeholder {
  color: #FFFFFF;
}

.username-input:focus-visible {
  border: 1px solid #FFFFFF !important;
  outline: none !important;
}

.update-button{
  background: #EE6486 !important;
  max-width: 95% !important;
  max-height: 60px !important;
}

.update-button-green{
  background: #6DB113 !important;
  max-width: 95% !important;
  max-height: 60px !important;
}


.error-text {
  color: #ee1e52;
  font-weight: 500;
}
.warning-title {
  color: #ee1e52;
}

.simple-spinner {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.simple-spinner span {
  display: block;
  width: 30px;
  height: 30px;
  border: 3px solid transparent;
  border-radius: 50%;
  border-right-color: rgba(255, 255, 255, 0.7);
  animation: spinner-anim 0.8s linear infinite;
}

@keyframes spinner-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
