.navbar-wrapper {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-top: 25px;
  padding-bottom: 20px;
}
.navbar-wrapper > button {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logout-wrapper {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #faf4fe;
}
.logout-wrapper > div > button {
  border-radius: 6px;
  border: none;
  padding-top: 7px;
  padding-bottom: 7px;
}
.logout-wrapper > div > button:nth-child(1) {
  background-color: #ee1e52;
}
.logout-wrapper > div > button:nth-child(2) {
  background-color: #5124ea;
}
.menu-wrapper > a {
  text-decoration: none;
  padding-bottom: 20px;
}
.menu-wrapper > button {
  border: none;
  padding: 0;
  margin: 0;
}
.menu-wrapper > a > span:nth-child(2) {
  padding-left: 10px;
}
.menu-wrapper > button > span:nth-child(2) {
  padding-left: 10px;
}
.user-info {
  min-height: 36px;
}
