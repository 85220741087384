.hl-2px {
  width: 2px !important;
  height: 100%;
  background-color: white;
}

.payment-padding-x {
  padding-left: 28px;
  padding-right: 28px;
}

.timer-content-w {
  width: calc(50% - 1px);
}

.timer-v-padding {
  padding-top: 6.32px;
  padding-bottom: 6.32px;
}

.timer {
  height: 60px;
}

.sale-text {
  margin-left: 11px;
}

.payment-section-v {
  margin-top: 30px;
  margin-bottom: 30px;
}

.payment-section-v-p1 {
  border: 1px solid #8A2FBC;
  border-radius: 10px;
  position: relative;
  padding: 10px 20px;
  text-align: center;
}

.discount-text {
  color: #B5B5B5;
  text-decoration: line-through;
  padding-right: 3px;
}

.price-text {
  padding-right: 3px;
}

.growth-sub-text {
  color: #707070;

}

.checklist-text {
  text-align: center;
  margin-bottom: 0px !important;
  padding-left: 10px;

}

.growth-list-container {
  padding-top: 20px;
  padding-bottom: 10px !important;
  gap: 5px !important;
}

.border-none-b {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.padding-b-20{
  padding-bottom: 20px;

}

.payment-line {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.border-none-t {
  border-top: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.per-day-text {
  background-color: #F4541A;
  padding: 7px 17px;
  border-radius: 17px;
  color: #ffffff;
}

.payment-section-pv {
  padding-top: 30px;
  padding-bottom: 30px;
}

.payment-section-b {
  margin-bottom: 30px;
}

.payment-btn {
  border-radius: 5px;
  border: none;
  box-shadow: 5px 5px 15px #00000042;
  width: 100%;
  color: var(--pure-white);
  font-weight: 800;
  font-size: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.plan-wrapper {
  border: 1px solid var(--vivid-raspberry);
  border-radius: 6px;
  padding: 10px 10px;
}

.offer-text {
  background-color: #f7f4f8;
  padding-left: 5px;
  padding-right: 5px;
}

.grow-img {
  max-width: 400px;
  object-fit: contain;
  width: 100%;
  padding-right: 20px;
  margin-bottom: 30px;
}

.featured-slider-wrapper {
  background-color: #faf4fe;
  padding-top: 30px;
  padding-bottom: 20px;
}

.featured-circle {
  bottom: 30px;
}

.featured-video-container {
  border: 3px solid var(--pure-white);
  border-radius: 14px;
  margin-left: 10px;
  max-width: 130px;
  overflow: hidden;
  transition: border 0.2s linear;
}

.featured-video-wrapper {
  transition: border 0.2s linear;
  height: 226px;
  cursor: pointer;
  transition: height 0.2s linear;
}

.featured-video-thumbnail {
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
  height: 270px;
  transition: height 0.2s linear;
}

.featured-video {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  height: 270px;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.featured-video-wrapper {
  position: relative;
}

.featured-profile-details {
  padding: 10px;
  gap: 5px;
  z-index: 3;
}

.featured-scroll-btn {
  display: flex;
  background-color: #6432ff;
  border-radius: 4px 0px 0px 4px;
  border: none;
  padding: 10px 22px;
}

.trust-wrapper {
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 15px;
}

.brands-text-m {
  margin-top: 4px;
  margin-bottom: 10px;
}

.covered-points-container {
  background-color: #f7f9fa;
  padding-bottom: 30px;
}

.more-text {
  color: #acacac;
}

.strategy-wrapper {
  border-radius: 5px;
  padding: 10px 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 21px;
  margin-right: 21px;
  gap: 15px;
  position: relative;
}

.strategy-wrapper img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.monthly-follower-text {
  background-color: #ffffff;
  color: black;
  text-align: center;
  padding: 10px;
  border-radius: 23px;
  margin-top: 5px;
  margin-bottom: 18px;
}

.plan-card {
  box-shadow: 5px 5px 15pt #00000042;
  border-radius: 10px;
}

.price-container {
  padding: 15px 13px;
  background-color: #8a2fbc;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.trial-price-wrapper {
  padding-bottom: 15px;
}

.trial-price-wrapper>p>span {
  padding-right: 10px;
}

.plan-card-line {
  height: 1px;
  width: 100%;
  background-color: #ffffff;
  opacity: 10%;
}

.total-price-wrapper {
  padding-top: 15px;
}

.payment-details-input {
  border: 1px solid #5124ea;
  border-radius: 6px;
  font-size: 18px;
  color: black;
  padding: 8px 15px;
  background-color: transparent;
  margin-bottom: 15px;
}

.payment-details-input::placeholder {
  color: #6432ff;
  opacity: 50%;
}

.payment-details-label {
  margin-bottom: 4px;
}

.email-username-input {
  gap: 5px;
}

.card-details {
  border: 1px solid #5124ea;
  border-radius: 6px;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 5px;
}

.cvc-input-wrapper {
  width: 30%;
}

.expiry-input-wrapper {
  width: 70%;
  padding-right: 10px;
}

.price-error {
  color: red;
}

.apple-pay-btn {
  background-color: black;
  border-radius: 30px;
}

.or-text {
  margin-top: 15px;
  margin-bottom: 15px;
}

.accordion-title {
  border: 1px solid #5124ea;
  border-radius: 6px;
  padding: 10px;
}

.accordion-item {
  margin-bottom: 10px;
}

.accordion-content {
  padding: 20px 10px;
}

.card-details-error {
  border-color: red;
}

.payment-input-error {
  color: red;
}

.threeDSecure iframe {
  height: 350px !important;
}

.active-video {
  height: 270px;
}

.translate-middle-video {
  transform: translate(-50%, -50%);
}

.active-video .featured-video-thumbnail {
  height: 270px;
}

.fade-button {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.fade-out {
  opacity: 0;
}

.fade-in {
  opacity: 1;
}

.swiper {
  overflow: visible !important;
  min-height: 278px;
}

.featured-video-wrapper,
.position-absolute {
  will-change: transform;
}

video::-webkit-media-controls {
  display: none !important;
}

.turnstile-custom {
  margin-bottom: 20px;
}

.tooltip-content-payment{
  background-color: #707070;
  border-radius: 5px;
  color: white;
}
.react-tooltip{
  opacity: 1 !important;
}

.tooltip-anchor-payment-active path{
  fill: #66AE08 !important;
}