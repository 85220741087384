.forgot-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.forgot-input {
  width: 100%;
  height: 40px;
  padding: 0 15px;
  margin-bottom: 15px;
  border: 1px solid #5124ea;
  border-radius: 6px;
  font-size: 14px;
}
.forgot-input:focus {
  outline: none;
  border-color: #5124ea;
}
.resent-button {
  color: #6432ff;
  margin: 0;
  padding: 0;
  border: none;
}
.forgot-input-wrapper {
  position: relative;
  margin-bottom: 10px;
}
.forgot-error-text {
  position: absolute;
  left: 0;
  bottom: 0px;
  color: #ff0000;
}
